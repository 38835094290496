<template>
    <div>
        <b-overlay :show="show" rounded="sm">
            <template v-if="pushNotification.length > 0">
                <b-table :items="pushNotification" responsive :fields="fields" class="mb-0" id="theme-table">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(Time)="data">
                        <div style="white-space: nowrap;">
                            {{ data.item.time }}
                        </div>
                    </template>
                    <template #cell(mascort)="data">
                        <img style="width: 50px; height: 50px" :src="data.item.mascort" :alt="data.item.mascort" />
                    </template>
                    <template #cell(action)="data" v-if="manage">
                        <div class="d-flex align-items-center">
                            <b-dropdown variant="link" no-caret>
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item @click="editThemeData(data.item)">
                                    <div>
                                        <feather-icon icon="EditIcon" />
                                        <span class="align-middle ml-50">Edit</span>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deletePushNotification(data.item)">
                                    <div>
                                        <b-spinner v-if="loading" small class="mr-1" />
                                        <feather-icon v-else icon="TrashIcon" />
                                        <span class="align-middle ml-50">Delete</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
            </template>
            <template v-else>
                <div class="text-center mb-2">No records found</div>
            </template>
        </b-overlay>
        <EditPushNotification :token="token" :notificationData="editTheme" @fetchThemes="fetchThemes"
            @removeThemeData="removeThemeData" :notificationTypes="notificationTypes"/>
    </div>
</template>

<script>
import {
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        EditPushNotification: () => import("./EditPushNotification.vue"),
        BButton,
        BFormInput,
        BFormGroup,
        BTable,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BSpinner,
        ToastificationContent,
    },
    data() {
        return {
            fields: [
                "index",
                { key: "triggerName", label: "Trigger Name" },
                { key: "time", label: "Time" },
                { key: "message", label: "message" },
                { key: "mascort", label: "Mascort" },
                { key: "personalized", label: "personalized" },
                { key: "condition", label: "condition" },
                { key: "action", label: "ACTION" },
            ],
            loading: false,
            editTheme: {},
            alertMessageToast: "",
        };
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        removeThemeData() {
            this.editTheme = "";
        },
        fetchThemes() {
            this.$emit('fetchNotifications');
        },
        editThemeData(theme) {
            this.editTheme = theme;
            this.$bvModal.show("edit-push-notification-modal");
        },
        deletePushNotification(item) {
            Swal.fire({
                title: "Are you sure?",
                text: `Do You want to delete "${item.triggerName}"!`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.delete(item._id)
                }
            });
        },
        async delete(id) {
            this.loading = true;
            try {
                const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/push-notification/${id}`, {
                    headers: {
                        Authorization: this.token,
                    },
                });

                if (resp.data.status === 200) {
                    this.alertMessageToast = resp.data.message;
                    this.showToast("success");
                    this.loading = false;
                    this.$emit('fetchNotifications');
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg = error.response.data.error || 'An error occurred';
                } else if (error.request) {
                    this.errorMsg = 'No response received from server. Please check your network connection.';
                } else {
                    this.errorMsg = 'Error in setting up the s: ' + error.message;
                }
                this.alertMessageToast = resp.data.message;
                this.showToast("danger");
                this.loading = false;
            }
        },
    },
    props: ["show", "pushNotification", "manage", "token","notificationTypes"],
};
</script>
<style>
#theme-table th:first-child {
    width: 100px;
}

#theme-table th:last-child {
    width: 100px;
}

#theme-table td {
    word-break: break-all;
}

#theme-table td:nth-child(3) {
    white-space: nowrap;
}
</style>
